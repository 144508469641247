import React from 'react'
import VideoTour from '../../Assets/VideoTour.mp4'
import './VisualTour.css'


function VisualTour() {
    return(<>
        <div>
            <div className='visualPage'>
              <video className='visualVideo' src={VideoTour} autoPlay muted loop />
            </div>
        </div>
    </>)
    
  }
    
export default VisualTour