import React, {useState} from 'react'
import { BrowserRouter as  Router, Routes, Route, Link} from 'react-router-dom';
// import MessengerCustomerChat from 'react-messenger-customer-chat';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import './App.css';
import ScrollToTop from './components/Loader/ScrollToTop';


import Home from '../src/Pages/Home/Home'
import Contact from '../src/Pages/Contact/Contact'
import Menu from '../src/Pages/Menu/Menu'

import Ensaladas from "./components/Ensaladas/Ensaladas"
import Sopas from './components/Sopas/Sopas';
import Paellas from './components/Paellas/Paellas';
import Pescados from './components/Pescados/Pescados';
import Carnes from './components/Carnes/Carnes';
import Aves from './components/Aves/Aves';
import Postres from './components/Postres/Postres';
import SideOrders from './components/SideOrders/SideOrders';

import logo from '../src/Assets/logo.png'
import { Drawer } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import 'antd/dist/antd.css';

function App() {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const [clicks, setClicks] = useState({
    home:false,
    menu:false,
    contact:false
  })

  const handleClick=(e)=>{
    const {name} = e.target
    var clickscopy = {...clicks}

    if(name==="home")
    {
      clickscopy["menu"] = false
      clickscopy["contact"] = false
    }
    if(name==="menu")
    {
      clickscopy["home"] = false
      clickscopy["contact"] = false
    }
    if(name==="contact")
    {
      clickscopy["menu"] = false
      clickscopy["home"] = false
    }

    clickscopy[name] = !clickscopy[name]
    setClicks(clickscopy)
  }

   const [fix, setFix] = useState(false)
   function setFixed(){
      if(window.scrollY){
        setFix(true)
      }
      else{
        setFix(false)
      }
   }
   window.addEventListener("scroll",setFixed)
  return (
    <div>
        <Router>
          <ScrollToTop />
          <div className={fix ? 'headerNav fixed' : 'headerNav'}>
            <div className='navigatorWeb'>
                <Col>
                  <img className='logo' src={logo}/>
                </Col>
                <Col>
                    <div className='navigator'>
                    <Link className={clicks.home?'navopt active':'navopt'} to="/" name="home" onClick={handleClick}>Home</Link>
                    <Link className={clicks.menu?'navopt active':'navopt'} to="/menu" name="menu" onClick={handleClick}>Menu</Link>
                    <Link className={clicks.contact?'navopt active':'navopt'} to="/contact" name="contact" onClick={handleClick}>Contact Us</Link>
                    
                  </div>
                </Col>
            </div>
            <div className="navigatorVisible">
                  
                  <FontAwesomeIcon type="primary" onClick={showDrawer} className='iconDrawer' icon={faBars}/>
                  
                  <Drawer className='drawerNav' width="40%" placement="left" onClose={onClose} open={open}>
                      <Row className='navLogo'>
                        <img className='logo' src={logo}/>
                      </Row>
                      <Row className='navRow'>
                      <Link className={clicks.home?'navopt active':'navopt'} to="/" name="home" onClick={handleClick}> Home</Link>
                      </Row>
                      <Row className='navRow'>
                      <Link className={clicks.menu?'navopt active':'navopt'} to="/menu" name="menu" onClick={handleClick}>Menu</Link>
                      </Row>
                      <Row className='navRow'>
                      <Link className={clicks.contact?'navopt active':'navopt'} to="/contact" name="contact" onClick={handleClick}>Contact Us</Link>
                      </Row>
                  </Drawer>
            </div>
          </div>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/contact" element={<Contact/>}/>
  
            <Route path="/ensaladas/*" element={<Ensaladas/>} />
            <Route path="/sopas" element={<Sopas/>} />
            <Route path="/paellas" element={<Paellas/>} />
            <Route path="/pescados" element={<Pescados/>} />
            <Route path="/carnes" element={<Carnes/>} />
            <Route path="/aves" element={<Aves/>} />
            <Route path="/postres" element={<Postres/>} />
            <Route path="/sideOrders" element={<SideOrders/>} />
          </Routes>
          
        </Router>
       
  
        {/* <MessengerCustomerChat
          pageId="<PAGE_ID>"
          appId="5946703932007437"
        /> */}
    
     
    </div>

  );
}

export default App;