import React,{useState} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Footer from '../../components/Footer/Footer'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';
import { Card, Modal} from 'react-bootstrap';
import { CircleFlag } from 'react-circle-flags'
import grab from '../../Assets/Modal/GrabFood.png'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import Menu from '../../Pages/Menu/Menu';
import Ensaladas from "../../components/Ensaladas/Ensaladas"
import Sopas from '../../components/Sopas/Sopas';
import Paellas from '../../components/Paellas/Paellas';
import Carnes from '../Carnes/Carnes';
import Aves from '../Aves/Aves';
import Postres from '../Postres/Postres';
import SideOrders from '../SideOrders/SideOrders';

import empty from '../../Assets/Tapas/Specialty1.jpg'
import alhorno from '../../Assets/Pescados/alhorno.png'
import marmitako from '../../Assets/Pescados/marmitako.png'
import suTinta from '../../Assets/Pescados/suTinta.png'
import salsaVerde from '../../Assets/Pescados/salsaVerde.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'

import "./Pescados.css";


function Pescados() {
    const { t } = useTranslation();

    function handleClick(lang) {
    i18next.changeLanguage(lang)
    }

    const [pescadosMarmitako, setMarmitako] = useState(false);
    const [pescadosSalsaVerde, setSalsaVerde] = useState(false);
    const [pescadosAjillo, setPescadoAjillo] = useState(false);
    const [pescaodsAlHorno, setAlHorno] = useState(false);
    const [pescadosSuTinta, setSuTinta] = useState(false);
    const [pescadosLaPlancha, setLaPlancha] = useState(false);
  return (
    <div className='menuContainer'>
      <div className='menuHeader'>
          <p>Our Menu</p>
      </div>
      <div className='options-container'>
        <div className='options-wrapper'>

          <div>
            <nav className='dropdownNav'>
              {[DropdownButton].map((DropdownType, idx) => (
                <DropdownType
                  as={ButtonGroup}
                  key={idx}
                  id={`dropdown-button-drop`}
                  size="sm"
                  variant="dark"
                  menuVariant="transparent"
                  title="Pescados y Mariscos"
                >
                  <Dropdown.Item eventKey="1"><Link to="/menu" className='menu-option'>Tapas</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="2"><Link to="/ensaladas" className='menu-option' name="ensaladas">Ensaladas</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="3"><Link to="/sopas" className='menu-option' name="sopas">Sopas</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="4"><Link to="/paellas" className='menu-option' name="paellas">Paellas</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="5"><Link className='menuPicked'>Pescados y Mariscos</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="6"><Link to="/carnes" className='menu-option' name="carnes">Carnes</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="7"><Link to="/aves" className='menu-option' name="aves">Aves</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="8"><Link to="/postres" className='menu-option' name="postres">Postres</Link></Dropdown.Item>
                  <Dropdown.Item eventKey=""><Link to="/sideOrders" className='menu-option' name="side">Side Orders</Link></Dropdown.Item>
                </DropdownType>
              ))}
            </nav>
            <nav className="navbar">
              <Link to="/menu" className='menu-option'>Tapas</Link>
              <Link to="/ensaladas" className='menu-option'>Ensaladas</Link>
              <Link to="/sopas" className='menu-option'>Sopas</Link>
              <Link to="/paellas" className='menu-option'>Paellas</Link>
              <Link className='menuPicked'>Pescados y Mariscos</Link>
              <Link to="/carnes" className='menu-option'>Carnes</Link>
              <Link to="/aves" className='menu-option'>Aves</Link>
              <Link to="/postres" className='menu-option'>Postres</Link>
              <Link to="/sideOrders" className='menu-option'>Side Orders</Link>

            </nav>
              <Routes>
              <Route path="/menu" element={<Menu />} />
                <Route path="/ensaladas/*" element={<Ensaladas />} />
                <Route path="/sopas" element={<Sopas/>} />
                <Route path="/paellas" element={<Paellas/>} />
                <Route path="/carnes" element={<Carnes/>} />
                <Route path="/aves" element={<Aves/>} />
                <Route path="/postres" element={<Postres/>} />
                <Route path="/sideOrders" element={<SideOrders/>} />
              </Routes>
          </div>


        </div>
      </div>
      <Row className='pescadosRow'>
            <Col sm={'auto'} style={{ width: "18vw" }}>
                <Card onClick={() => setMarmitako(true)}className='card'>
                    <Card.Img className='cardImage'variant="top" src={marmitako} />
                    <Card.Body>
                    <Card.Title className='cardTitle'>MARMITAKO</Card.Title>
                    </Card.Body>
                </Card>
                <Modal className='modalForm' backdrop="static" show={pescadosMarmitako} onHide={() => setMarmitako(false)} closeButton>
                <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setMarmitako(false)}/>
                        <img className='modalImage' src={marmitako}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('pescadosTitle.1')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('pescadosDescription.1')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱400.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$6.79</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€6.95</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </Col>
            <Col sm={'auto'} style={{ width: "18vw" }}>
                <Card onClick={() => setSalsaVerde(true)}className='card'>
                    <Card.Img className='cardImage'variant="top" src={salsaVerde} />
                    <Card.Body>
                    <Card.Title className='cardTitle'>PESCADO EN SALSA VERDE </Card.Title>
                    </Card.Body>
                </Card>
                <Modal className='modalForm' backdrop="static" show={pescadosSalsaVerde} onHide={() => setSalsaVerde(false)} closeButton>
                <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setSalsaVerde(false)}/>
                        <img className='modalImage' src={salsaVerde}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('pescadosTitle.2')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('pescadosDescription.2')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱470.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$7.98</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€8.17</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
            </Col>
            <Col className='pescadosCol3' sm={'auto'} style={{ width: "18vw" }}>
                <Card onClick={() => setPescadoAjillo(true)}className='card'>
                    <Card.Img className='cardImage'variant="top" src={empty} />
                    <Card.Body>
                    <Card.Title className='cardTitle'>PESCADO AL AJILLO</Card.Title>
                    </Card.Body>
                </Card>
                <Modal className='modalForm' backdrop="static" show={pescadosAjillo} onHide={() => setPescadoAjillo(false)} closeButton>
                <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setPescadoAjillo(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('pescadosTitle.3')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                   
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('pescadosDescription.3')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱400.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$6.79</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€6.95</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
            </Col>
            <Col className='pescadosCol4' sm={'auto'} style={{ width: "18vw" }}>
                <Card onClick={() => setAlHorno(true)}className='card'>
                    <Card.Img className='cardImage'variant="top" src={alhorno} />
                    <Card.Body>
                    <Card.Title className='cardTitle'>PESCADO AL HORNO </Card.Title>
                    </Card.Body>
                </Card>
                <Modal className='modalForm' backdrop="static" show={pescaodsAlHorno} onHide={() => setAlHorno(false)} closeButton>
                <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setAlHorno(false)}/>
                        <img className='modalImage' src={alhorno}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('pescadosTitle.4')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                   
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('pescadosDescription.4')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱480.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$8.15</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€8.34</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
            </Col>
            <Col className='pescadosCol4' sm={'auto'} style={{ width: "18vw" }}>
                <Card onClick={() => setSuTinta(true)}className='card'>
                    <Card.Img className='cardImage'variant="top" src={suTinta} />
                    <Card.Body>
                    <Card.Title className='cardTitle'>CHIPIRONES EN SU TINTA</Card.Title>
                    </Card.Body>
                </Card>
                <Modal className='modalForm' backdrop="static" show={pescadosSuTinta} onHide={() => setSuTinta(false)} closeButton>
                <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setSuTinta(false)}/>
                        <img className='modalImage' src={suTinta}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('pescadosTitle.5')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('pescadosDescription.5')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱450.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$7.64</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€7.82</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
            </Col>
            <Col className='pescadosCol4' sm={'auto'} style={{ width: "18vw" }}>
                <Card onClick={() => setLaPlancha(true)}className='card'>
                    <Card.Img className='cardImage'variant="top" src={empty} />
                    <Card.Body>
                    <Card.Title className='cardTitle'>LANGOSTINOS A LA PLANCHA</Card.Title>
                    </Card.Body>
                </Card>
                <Modal className='modalForm' backdrop="static" show={pescadosLaPlancha} onHide={() => setLaPlancha(false)} closeButton>
                <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setLaPlancha(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('pescadosTitle.6')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('pescadpsDescription.6')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱600.00</b></p>
                                        </Col>
                                        <Col className='col-3 menuTwo'>
                                            <p className='priceName'><b>$10.19</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€10.42</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
            </Col>
        </Row>
        <Footer/>
      </div>
  )
}

export default Pescados