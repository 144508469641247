import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Gallery1 from '../../Assets/Gallery/gallery1.png' 
import Gallery2 from '../../Assets/Gallery/gallery2.png' 
import Gallery3 from '../../Assets/Gallery/gallery3.png' 
import Gallery4 from '../../Assets/Gallery/gallery4.png' 
import Gallery5 from '../../Assets/Gallery/gallery5.png' 
import Gallery6 from '../../Assets/Gallery/gallery6.png' 
import Gallery7 from '../../Assets/Gallery/gallery7.png' 
import Gallery8 from '../../Assets/Gallery/gallery8.png' 
import Gallery9 from '../../Assets/Gallery/gallery9.png' 
import Gallery10 from '../../Assets/Gallery/gallery10.png' 
import Gallery11 from '../../Assets/Gallery/gallery11.png' 
import Gallery12 from '../../Assets/Gallery/gallery12.png' 
import Gallery13 from '../../Assets/Gallery/gallery13.png' 
import Gallery14 from '../../Assets/Gallery/gallery14.png' 
import Gallery15 from '../../Assets/Gallery/gallery15.png' 



import './OurGallery.css'




function OurGallery() {
    return(<>
        <div className='ourGalleryContainer'>
            <div className='ourGalleryHeader'>
                <p>Our Gallery</p>
            </div>
            <Row>
               <Col className='galleryFirstCol'>
                  <Row className='galleryRow'>
                     <img
                        src={Gallery2}
                        className="gallery"
                     />
                  </Row>
                  <Row className='galleryRow'>
                     <img
                        src={Gallery4}
                        className="gallery"
                     />
                  </Row>
                  <Row className='galleryRow'>
                     <img
                        src={Gallery7}
                        className="gallery"
                     />
                  </Row>
                  <Row className='galleryRow'>
                     <img
                        src={Gallery11}
                        className="gallery"
                     />
                  </Row>
                  
               </Col>
               <Col className='gallerySecondCol'>
                  <Row className='galleryRow'>
                     <img
                        src={Gallery3}
                        className="gallery"
                     />
                  </Row>
                  <Row className='galleryRow'>
                     <img
                        src={Gallery8}
                        className="gallery"
                     />
                  </Row>
                  <Row className='galleryRow'>
                     <img
                        src={Gallery12}
                        className="gallery"
                     />
                  </Row>
                  <Row className='galleryRow'>
                     <img
                        src={Gallery14}
                        className="gallery"
                     />
                  </Row>
               </Col>
               <Col className='galleryThirdCol'>
                  <Row className='galleryRow'>
                     <img
                        src={Gallery1}
                        className="gallery"
                     />
                  </Row>
                  <Row className='galleryRow'>
                     <img
                        src={Gallery9}
                        className="gallery"
                     />
                  </Row>
                  <Row className='galleryRow'>
                     <img
                        src={Gallery13}
                        className="gallery"
                     />
                  </Row>
               </Col>
               <Col className='galleryLastCol'>
                  <Row className='galleryRow'>
                     <img
                        src={Gallery5}
                        className="gallery"
                     />
                  </Row>
                  <Row className='galleryRow'>
                     <img
                        src={Gallery6}
                        className="gallery"
                     />
                  </Row>
                  <Row className='galleryRow'>
                     <img
                        src={Gallery10}
                        className="gallery"
                     />
                  </Row>
                  <Row className='galleryRow'>
                     <img
                        src={Gallery15}
                        className="gallery"
                     />
                  </Row>
               </Col>

            </Row>  
        </div>
    
    </>)
}

    
export default OurGallery