import React, {useState,useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Card, Modal} from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.css";

import { BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import Ensaladas from "../../components/Ensaladas/Ensaladas"
import Sopas from '../../components/Sopas/Sopas';
import Paellas from '../../components/Paellas/Paellas';
import Pescados from '../../components/Pescados/Pescados';
import Carnes from '../../components/Carnes/Carnes';
import Aves from '../../components/Aves/Aves';
import Postres from '../../components/Postres/Postres';
import SideOrders from '../../components/SideOrders/SideOrders';
import { CircleFlag } from 'react-circle-flags'

import empty from '../../Assets/Tapas/Specialty1.jpg'
import chorizo from '../../Assets/Tapas/chorizo.png'
import albondigas from '../../Assets/Tapas/albondigas.png'
import croquetas from '../../Assets/Tapas/croquetas.png'
import gambasAlAjillo from '../../Assets/Tapas/gambasAlAjillo.png'
import quesoFrito from '../../Assets/Tapas/queso-frito.png'
import sardinas from '../../Assets/Tapas/sardinas.png'
import tiras from '../../Assets/Tapas/tiras.png'
import tortillaDePatata from '../../Assets/Tapas/tortillaDePatata.png'

import grab from '../../Assets/Modal/GrabFood.png'
import Footer from '../../components/Footer/Footer'
import '../../components/Loader/loader.css'

import "./Menu.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'



function Menu() {
    const [pick, setPick] = useState({
        tapas:false,
        ensaladas:false,
        sopas:false,
        paellas:false,
        pescados:false,
        carnes:false,
        aves:false,
        postres:false,
        side:false

      })

      const handlePick=(e)=>{
        const {name} = e.target
        var clickscopy = {...pick}

        if(name==="tapas")
        {
          clickscopy["ensaladas"] = false
          clickscopy["sopas"] = false
          clickscopy["paellas"] = false
          clickscopy["piscados"] = false
          clickscopy["carnes"] = false
          clickscopy["aves"] = false
          clickscopy["postres"] = false
          clickscopy["side"] = false
        }
        if(name==="ensaladas")
        {
            clickscopy["tapas"] = false
            clickscopy["sopas"] = false
            clickscopy["paellas"] = false
            clickscopy["piscados"] = false
            clickscopy["carnes"] = false
            clickscopy["aves"] = false
            clickscopy["postres"] = false
            clickscopy["side"] = false
        }
        if(name==="sopas")
        {
            clickscopy["ensaladas"] = false
            clickscopy["tapas"] = false
            clickscopy["paellas"] = false
            clickscopy["piscados"] = false
            clickscopy["carnes"] = false
            clickscopy["aves"] = false
            clickscopy["postres"] = false
            clickscopy["side"] = false
        }
        if(name==="paellas")
        {
            clickscopy["ensaladas"] = false
            clickscopy["sopas"] = false
            clickscopy["tapas"] = false
            clickscopy["piscados"] = false
            clickscopy["carnes"] = false
            clickscopy["aves"] = false
            clickscopy["postres"] = false
            clickscopy["side"] = false
        }
        if(name==="piscados")
        {
            clickscopy["ensaladas"] = false
            clickscopy["sopas"] = false
            clickscopy["paellas"] = false
            clickscopy["tapas"] = false
            clickscopy["carnes"] = false
            clickscopy["aves"] = false
            clickscopy["postres"] = false
            clickscopy["side"] = false
        }
        if(name==="carnes")
        {
            clickscopy["ensaladas"] = false
            clickscopy["sopas"] = false
            clickscopy["paellas"] = false
            clickscopy["piscados"] = false
            clickscopy["tapas"] = false
            clickscopy["aves"] = false
            clickscopy["postres"] = false
            clickscopy["side"] = false
        }
        if(name==="aves")
        {
            clickscopy["ensaladas"] = false
            clickscopy["sopas"] = false
            clickscopy["paellas"] = false
            clickscopy["piscados"] = false
            clickscopy["carnes"] = false
            clickscopy["tapas"] = false
            clickscopy["postres"] = false
            clickscopy["side"] = false
        }
        if(name==="postres")
        {
            clickscopy["ensaladas"] = false
            clickscopy["sopas"] = false
            clickscopy["paellas"] = false
            clickscopy["piscados"] = false
            clickscopy["carnes"] = false
            clickscopy["aves"] = false
            clickscopy["tapas"] = false
            clickscopy["side"] = false
        }
        if(name==="side")
        {
            clickscopy["ensaladas"] = false
            clickscopy["sopas"] = false
            clickscopy["paellas"] = false
            clickscopy["piscados"] = false
            clickscopy["carnes"] = false
            clickscopy["aves"] = false
            clickscopy["postres"] = false
            clickscopy["tapas"] = false
        }

        clickscopy[name] = !clickscopy[name]
        setPick(clickscopy)
      }

       const [fix, setFix] = useState(false)
       function setFixed(){
          if(window.scrollY){
            setFix(true)
          }
          else{
            setFix(false)
          }
       }
       window.addEventListener("scroll",setFixed)

    const { t } = useTranslation();

    function handleClick(lang) {
        i18next.changeLanguage(lang)
    }

    const [ajillo, setAjillo] = useState(false);
    const [conChorizo, setConChorizo] = useState(false);
    const [conGulas, setConGulas] = useState(false);
    const [spanChorizo, setSpanChorizo] = useState(false);
    const [dePatata, setDePatata] = useState(false);
    const [aliOli, setAliOli] = useState(false);
    const [dePollo, setDePollo] = useState(false);
    const [deCerdo, setDeCerdo] = useState(false);
    const [tirasDePollo, setTirasDePollo] = useState(false);
    const [albodigasMenu, setAlbodigas] = useState(false);
    const [calamares, setCalamares] = useState(false);
    const [rebozado, setRebozado] = useState(false);
    const [laRomana, setLaRomana] = useState(false);
    const [champinones, setChampinones] = useState(false);
    const [queso, setQueso] = useState(false);
    const [milkFish, setMilkFish] = useState(false);
    const [enVinagre, setEnVinagre] = useState(false);
    const [guinadilla, setGuinadilla] = useState(false);
    const [pimiento, setPimiento] = useState(false);
    const [anchoa, setAnchoa] = useState(false);
    
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        document.body.style.background = "black";
        if (document.readyState === "complete") {
            setTimeout(function() {
                setLoading(true);
                document.body.style.background = "white";
            }, 4000);
        }
    }, []);

    {
        (function(){
            window.addEventListener('load', function() {
                setLoading(true);
            });
        })();
    }
    {
        return !loading? (
            <div className="loader">
            <span className="hour"></span>
            <span className="min"></span>
            <span className="circel"></span>
            </div>
        ):(
        <div className='menuContainer'>

            <div className='menuHeader'>
                <p>Our Menu</p>
            </div>

            <div className='options-wrapper'>
              <div>
                  <nav className='dropdownNav'>
                    {[DropdownButton].map((DropdownType, idx) => (
                      <DropdownType
                        as={ButtonGroup}
                        key={idx}
                        id={`dropdown-button-drop`}
                        size="sm"
                        variant="dark"
                        menuVariant="transparent"
                        title="Tapas"
                      >
                        <Dropdown.Item eventKey="1"><Link to="/menu" className={pick.tapas?'menuPicked active':'menuPicked'} name="tapas" onClick={handlePick}>Tapas</Link></Dropdown.Item>
                        <Dropdown.Item eventKey="2"><Link to="/ensaladas" className='menu-option' name="ensaladas">Ensaladas</Link></Dropdown.Item>
                        <Dropdown.Item eventKey="3"><Link to="/sopas" className='menu-option' name="sopas">Sopas</Link></Dropdown.Item>
                        <Dropdown.Item eventKey="4"><Link to="/paellas" className='menu-option' name="paellas">Paellas</Link></Dropdown.Item>
                        <Dropdown.Item eventKey="5"><Link to="/pescados" className='menu-option' name="pescados">Pescados y Mariscos</Link></Dropdown.Item>
                        <Dropdown.Item eventKey="6"><Link to="/carnes" className='menu-option' name="carnes">Carnes</Link></Dropdown.Item>
                        <Dropdown.Item eventKey="7"><Link to="/aves" className='menu-option' name="aves">Aves</Link></Dropdown.Item>
                        <Dropdown.Item eventKey="8"><Link to="/postres" className='menu-option' name="postres">Postres</Link></Dropdown.Item>
                        <Dropdown.Item eventKey=""><Link to="/sideOrders" className='menu-option' name="side">Side Orders</Link></Dropdown.Item>
                        </DropdownType>
                    ))}
                  </nav>
                  <nav className="navbar">
                    <Link to="/menu" className={pick.tapas?'menuPicked active':'menuPicked'} name="tapas" onClick={handlePick}>Tapas</Link>
                    <Link to="/ensaladas" className='menu-option' name="ensaladas">Ensaladas</Link>
                    <Link to="/sopas" className='menu-option' name="sopas">Sopas</Link>
                    <Link to="/paellas" className='menu-option' name="paellas">Paellas</Link>
                    <Link to="/pescados" className='menu-option' name="pescados">Pescados y Mariscos</Link>
                    <Link to="/carnes" className='menu-option' name="carnes">Carnes</Link>
                    <Link to="/aves" className='menu-option' name="aves">Aves</Link>
                    <Link to="/postres" className='menu-option' name="postres">Postres</Link>
                    <Link to="/sideOrders" className='menu-option' name="side">Side Orders</Link>

                  </nav>
                  <Routes>
                      <Route path="/menu" element={<Menu/>} />
                      <Route path="/ensaladas/*" element={<Ensaladas/>} />
                      <Route path="/sopas" element={<Sopas/>}/>
                      <Route path="/paellas" element={<Paellas/>}/>
                      <Route path="/pescados" element={<Pescados/>}/>
                      <Route path="/carnes" element={<Carnes/>}/>
                      <Route path="/aves" element={<Aves/>}/>
                      <Route path="/postres" element={<Postres/>}/>
                      <Route path="/sideOrders" element={<SideOrders/>}/>

                  </Routes>
              </div>
            </div>
            <Row className='menuRow'>
                <Col sm={'auto'} style={{ width: "18vw" }}>
                    <Card onClick={() => setAjillo(true)} className='card'>
                        <Card.Img className='cardImage'variant="top" src={gambasAlAjillo} />
                        <Card.Body>
                        <Card.Title className='cardTitle'>GAMBAS AL AJILLO</Card.Title>
                        </Card.Body>
                    </Card>
                    <Modal className='modalForm' backdrop="static" show={ajillo} onHide={() => setAjillo(false)} >
                        <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setAjillo(false)}/>
                        <img className='modalImage' src={gambasAlAjillo}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('tapasTitle.1')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('tapasDescription.1')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱320.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$5.43</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€5.57</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </Col>

                <Col sm={'auto'} style={{ width: "18vw" }}>
                    <Card onClick={() => setConChorizo(true)} className='card'>
                        <Card.Img className='cardImage'variant="top" src={empty} />
                        <Card.Body>
                        <Card.Title className='cardTitle'>GAMBAS CON CHORIZO</Card.Title>
                        </Card.Body>
                    </Card>
                    <Modal className='modalForm' backdrop="static" show={conChorizo} onHide={() => setConChorizo(false)} closeButton>
                        <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setConChorizo(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('tapasTitle.2')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('tapasDescription.2')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱480.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$8.14</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€8.33</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </Col>
                <Col sm={'auto'} style={{ width: "18vw" }}>
                    <Card onClick={() => setConGulas(true)} className='card'>
                        <Card.Img className='cardImage'variant="top" src={empty} />
                        <Card.Body>
                        <Card.Title className='cardTitle'>GAMBAS AL AJILLO<br></br>CON GULAS</Card.Title>
                        </Card.Body>
                    </Card>
                    <Modal className='modalForm' backdrop="static"  show={conGulas} onHide={() => setConGulas(false)} closeButton>
                        <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setConGulas(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('tapasTitle.3')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('tapasDescription.3')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱450.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$7.63</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€7.81</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </Col>
                <Col sm={'auto'} style={{ width: "18vw" }}>
                    <Card onClick={() => setSpanChorizo(true)} className='card'>
                        <Card.Img className='cardImage'variant="top" src={chorizo} />
                        <Card.Body>
                        <Card.Title className='cardTitle'>SPANISH CHORIZO</Card.Title>
                        </Card.Body>
                    </Card>
                    <Modal className='modalForm' backdrop="static"  show={spanChorizo} onHide={() => setSpanChorizo(false)} closeButton>
                        <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setSpanChorizo(false)}/>
                        <img className='modalImage' src={chorizo}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('tapasTitle.4')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('tapasDescription.4')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱250.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$4.24</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€4.34</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </Col>

            </Row>
            <Row className='menuRow'>
                <Col sm={'auto'} style={{ width: "18vw" }}>
                    <Card onClick={() => setDePatata(true)}className='card'>
                        <Card.Img className='cardImage'variant="top" src={tortillaDePatata} />
                        <Card.Body>
                        <Card.Title className='cardTitle'>TORTILLA DE PATATA</Card.Title>
                        </Card.Body>
                    </Card>
                    <Modal className='modalForm' backdrop="static"  show={dePatata} onHide={() => setDePatata(false)} closeButton>
                    <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setDePatata(false)}/>
                        <img className='modalImage' src={tortillaDePatata}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('tapasTitle.5')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('tapasDescription.5')}</p>
                                    </Row>
                                    <Row>
                                        <Row className='pax'>
                                            <Col className='col-10'>
                                                <p className='menuDescription'>Good for 6 Pax</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='col-3 menuThree'>
                                                <p className='priceName'><b>₱285.00</b></p>
                                            </Col>
                                            <Col className='col-2 menuTwo'>
                                                <p className='priceName'><b>$4.83</b></p>
                                            </Col>
                                            <Col className='col-2 menuTwo'>
                                                <p className='priceName'><b>€4.95</b></p>
                                            </Col>
                                        </Row>
                                    </Row>
                                    <Row>
                                        <Row className='pax'>
                                            <Col className='col-10'>
                                                <p className='menuDescription'>Good for 1 Person</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='col-3 menuThree'>
                                                <p className='priceName'><b>₱150.00</b></p>
                                            </Col>
                                            <Col className='col-2 menuTwo'>
                                                <p className='priceName'><b>$2.54</b></p>
                                            </Col>
                                            <Col className='col-2 menuTwo'>
                                                <p className='priceName'><b>€2.60</b></p>
                                            </Col>
                                        </Row>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </Col>
                <Col sm={'auto'} style={{ width: "18vw" }}>
                    <Card onClick={() => setAliOli(true)}className='card'>
                        <Card.Img className='cardImage'variant="top" src={empty} />
                        <Card.Body>
                        <Card.Title className='cardTitle'>PATATAS ALI OLI</Card.Title>
                        </Card.Body>
                    </Card>
                    <Modal className='modalForm' backdrop="static"  show={aliOli} onHide={() => setAliOli(false)} closeButton>
                        <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setAliOli(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('tapasTitle.6')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('tapasDescription.6')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱160.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$2.71</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€2.78</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </Col>
                <Col sm={'auto'} style={{ width: "18vw" }}>
                    <Card onClick={() => setDePollo(true)}className='card'>
                        <Card.Img className='cardImage'variant="top" src={croquetas} />
                        <Card.Body>
                        <Card.Title className='cardTitle'>CROQUETAS DE POLLO</Card.Title>
                        </Card.Body>
                    </Card>
                    <Modal className='modalForm' backdrop="static"  show={dePollo} onHide={() => setDePollo(false)} closeButton>
                        <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setDePollo(false)}/>
                        <img className='modalImage' src={croquetas}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('tapasTitle.7')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('tapasDescription.7')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱150.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$2.54</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€2.60</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </Col>
                <Col sm={'auto'} style={{ width: "18vw" }}>
                    <Card onClick={() => setDeCerdo(true)}className='card'>
                        <Card.Img className='cardImage'variant="top" src={empty} />
                        <Card.Body>
                        <Card.Title className='cardTitle'>MAGRO DE CERDO CON<br></br>TOMATE</Card.Title>
                        </Card.Body>
                    </Card>
                    <Modal className='modalForm' backdrop="static"  show={deCerdo} onHide={() => setDeCerdo(false)} closeButton>
                    <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setDeCerdo(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('tapasTitle.8')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('tapasDescription.8')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱320.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$5.43</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€5.57</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </Col>

            </Row>

            <Row className='menuRow'>
                <Col sm={'auto'} style={{ width: "18vw" }}>
                    <Card onClick={() => setTirasDePollo(true)}className='card'>
                        <Card.Img className='cardImage'variant="top" src={tiras} />
                        <Card.Body>
                        <Card.Title className='cardTitle'>TIRAS DE POLLO</Card.Title>
                        </Card.Body>
                    </Card>
                    <Modal className='modalForm' backdrop="static"  show={tirasDePollo} onHide={() => setTirasDePollo(false)} closeButton>
                    <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setTirasDePollo(false)}/>
                        <img className='modalImage' src={tiras}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('tapasTitle.9')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('tapasDescription.9')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱200.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$3.39</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€3.47</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </Col>
                <Col sm={'auto'} style={{ width: "18vw" }}>
                    <Card onClick={() => setAlbodigas(true)}className='card'>
                        <Card.Img className='cardImage'variant="top" src={albondigas} />
                        <Card.Body>
                        <Card.Title className='cardTitle'>ALBONDIGAS</Card.Title>
                        </Card.Body>
                    </Card>
                    <Modal className='modalForm' backdrop="static"  show={albodigasMenu} onHide={() => setAlbodigas(false)} closeButton>
                    <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setAlbodigas(false)}/>
                        <img className='modalImage' src={albondigas}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('tapasTitle.10')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('tapasDescription.10')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱250.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$4.24</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€4.34</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </Col>
                <Col sm={'auto'} style={{ width: "18vw" }}>
                    <Card onClick={() => setCalamares(true)}className='card'>
                        <Card.Img className='cardImage'variant="top" src={empty} />
                        <Card.Body>
                        <Card.Title className='cardTitle'>CALAMARES FRITO</Card.Title>
                        </Card.Body>
                    </Card>
                    <Modal className='modalForm' backdrop="static"  show={calamares} onHide={() => setCalamares(false)} closeButton>
                    <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setCalamares(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('tapasTitle.11')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('tapasDescription.11')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱320.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$5.43</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€5.57</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </Col>
                <Col sm={'auto'} style={{ width: "18vw" }}>
                    <Card onClick={() => setRebozado(true)}className='card'>
                        <Card.Img className='cardImage'variant="top" src={empty} />
                        <Card.Body>
                        <Card.Title className='cardTitle'>REBOZADOS</Card.Title>
                        </Card.Body>
                    </Card>
                    <Modal className='modalForm' backdrop="static"  show={rebozado} onHide={() => setRebozado(false)} closeButton>
                    <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setRebozado(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('tapasTitle.12')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('tapasDescription.12')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱320.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$5.43</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€5.57</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </Col>

            </Row>

            <Row className='menuRow'>
                <Col sm={'auto'} style={{ width: "18vw" }}>
                    <Card onClick={() => setChampinones(true)}className='card'>
                        <Card.Img className='cardImage'variant="top" src={empty} />
                        <Card.Body>
                        <Card.Title className='cardTitle'>CHAMPIÑONES AL AJILLO</Card.Title>
                        </Card.Body>
                    </Card>
                    <Modal className='modalForm' backdrop="static"  show={champinones} onHide={() => setChampinones(false)} closeButton>
                    <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setChampinones(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('tapasTitle.13')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('tapasDescription.13')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱180.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$3.05</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€3.12</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </Col>
                <Col sm={'auto'} style={{ width: "18vw" }}>
                    <Card onClick={() => setQueso(true)}className='card'>
                        <Card.Img className='cardImage'variant="top" src={quesoFrito} />
                        <Card.Body>
                        <Card.Title className='cardTitle'>QUESO FRITO</Card.Title>
                        </Card.Body>
                    </Card>
                    <Modal className='modalForm' backdrop="static"  show={queso} onHide={() => setQueso(false)} closeButton>
                    <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setQueso(false)}/>
                        <img className='modalImage' src={quesoFrito}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('tapasTitle.14')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('tapasDescription.14')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱150.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$2.55</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€2.60</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </Col>
                <Col sm={'auto'} style={{ width: "18vw" }}>
                    <Card onClick={() => setMilkFish(true)}className='card'>
                        <Card.Img className='cardImage'variant="top" src={empty} />
                        <Card.Body>
                        <Card.Title className='cardTitle'>MILKFISH	PÂTÉ</Card.Title>
                        </Card.Body>
                    </Card>
                    <Modal className='modalForm' backdrop="static"  show={milkFish} onHide={() => setMilkFish(false)} closeButton>
                    <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setMilkFish(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('tapasTitle.15')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('tapasDescription.15')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱180.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$3.05</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€3.12</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </Col>
                <Col sm={'auto'} style={{ width: "18vw" }}>
                    <Card onClick={() => setEnVinagre(true)}className='card'>
                        <Card.Img className='cardImage'variant="top" src={sardinas} />
                        <Card.Body>
                        <Card.Title className='cardTitle'>SARDINAS EN VINAGRE</Card.Title>
                        </Card.Body>
                    </Card>
                    <Modal className='modalForm' backdrop="static"  show={enVinagre} onHide={() => setEnVinagre(false)} closeButton>
                    <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setEnVinagre(false)}/>
                        <img className='modalImage' src={sardinas}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('tapasTitle.16')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('tapasDescription.16')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱200.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$3.39</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€3.47</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </Col>

            </Row>

            <Row className='menuRow'>
            <Col sm={'auto'} style={{ width: "18vw" }}>
                    <Card onClick={() => setLaRomana(true)}className='newCard'>
                        <Card.Img className='cardImage'variant="top" src={empty} />
                        <Card.Body className='lastMenuBody'>
                        <Card.Title className='cardTitle'>A LA ROMANA</Card.Title>
                        </Card.Body>
                    </Card>
                    <Modal className='modalForm' backdrop="static"  show={laRomana} onHide={() => setLaRomana(false)} closeButton>
                    <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setLaRomana(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('tapasTitle.17')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('tapasDescription.17')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱200.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$3.39</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€3.47</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </Col>
                <Col sm={'auto'} style={{ width: "18vw" }}>
                    <Card onClick={() => setGuinadilla(true)}className='newCard'>
                        <Card.Img className='cardImage'variant="top" src={empty} />
                        <Card.Body className='lastMenuBody'>
                        <Card.Title className='cardTitle'>SARDINAS EN VINAGRE CON<br></br>REFRITO DE AJO Y GUINDILLA</Card.Title>
                        </Card.Body>
                    </Card>
                    <Modal className='modalForm' backdrop="static"  show={guinadilla} onHide={() => setGuinadilla(false)} closeButton>
                    <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setGuinadilla(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('tapasTitle.18')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row className='w-5'>
                                        <p className='menuDescription'>{t('tapasDescription.18')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱200.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$3.39</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€3.47</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </Col>
                <Col sm={'auto'} style={{ width: "18vw" }}>
                    <Card onClick={() => setPimiento(true)}className='newCard'>
                        <Card.Img className='cardImage'variant="top" src={empty} />
                        <Card.Body className='lastMenuBody'>
                        <Card.Title className='cardTitle'>ACEITUNAS MANZANILLA<br></br>RELLENAS DE PIMIENTO</Card.Title>
                        </Card.Body>
                    </Card>
                    <Modal className='modalForm' backdrop="static"  show={pimiento} onHide={() => setPimiento(false)} closeButton>
                    <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setPimiento(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('tapasTitle.19')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('tapasDescription.19')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱200.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$3.39</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€3.47</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </Col>
                <Col sm={'auto'} style={{ width: "18vw" }}>
                    <Card onClick={() => setAnchoa(true)}className='newCard'>
                        <Card.Img className='cardImage'variant="top" src={empty} />
                        <Card.Body className='lastMenuBody'>
                        <Card.Title className='cardTitle'>ACEITUNAS MANZANILLA<br></br>RELLENAS DE ANCHOA</Card.Title>
                        </Card.Body>
                    </Card>
                    <Modal className='modalForm' backdrop="static"  show={anchoa} onHide={() => setAnchoa(false)} closeButton>
                    <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setAnchoa(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('tapasTitle.20')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('tapasDescription.20')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱200.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$3.39</b></p>
                                        </Col>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>€3.47</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </Col>
            </Row>


            <Footer/>

        </div>
        )
    }
}

export default Menu