
import React, {useState,useEffect} from 'react'
import {Carousel} from 'react-bootstrap'

// Images
import home1 from '../../Assets/home1.png'
import home2 from '../../Assets/home2.png'
import home3 from '../../Assets/home3.png'

// CSS
import './Home.css'

// Components

import AboutUS from '../../components/AboutUs/AboutUs'
import VisualTour from '../../components/VisualTour/VisualTour'
import HouseSpecialty from '../../components/HouseSpecialty/HouseSpecialty'
import OurGallery from '../../components/OurGallery/OurGallery'
import Footer from '../../components/Footer/Footer'
import '../../components/Loader/loader.css'
const Home = () => {

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.body.style.background = "black";
        if (document.readyState === "complete") {
            setTimeout(function() {
                setLoading(true);
                document.body.style.background = "white";
            }, 2000);
        }
    }, []);

    {
        (function(){
            window.addEventListener('load', function() {
                setLoading(true);
            });
        })();
    }

    {
        return !loading? (
            <div className="loader">
            <span className="hour"></span>
            <span className="min"></span>
            <span className="circel"></span>
            </div>
        ):(
            <div>
                    <div className='homePage' style={{overflow: 'hidden'}}>
                        <Carousel>
                            <Carousel.Item interval={4000}>
                                <img
                                    className="d-block w-100 imgCarousel"
                                    src={home1}
                                    alt="First slide"
                                />
                                <Carousel.Caption>
                                    <p className='text-container'>Experience authentic & delicious Spanish food at the Queen City of the South at Ipar’s Restaurante Y Bar de Tapas. </p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item interval={4000}>
                                <img
                                    className="d-block w-100 imgCarousel"
                                    src={home2}
                                    alt="Second slide"
                                />
                                <Carousel.Caption>
                                    <p className='text-container'>Come and celebrate with your family & friends. Cozy ambience, friendly service and located in the heart of Cebu!</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item interval={4000}>
                                <img
                                    className="d-block w-100 imgCarousel"
                                    src={home3}
                                    alt="Third slide"
                                />
                                <Carousel.Caption>
                                    <p className='text-container'>We have the best paellas, delectable tapas, and we boast that our leche flan is beyond delicious!</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
    
                        <AboutUS/>
                        <VisualTour/>
                        <HouseSpecialty/>
                        <OurGallery/>
                        <Footer/>
                    </div>     
            </div>
        )
    }
}

export default Home
