import React, {useState} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Footer from '../../components/Footer/Footer'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';
import { Card, Modal} from 'react-bootstrap';
import { CircleFlag } from 'react-circle-flags'
import grab from '../../Assets/Modal/GrabFood.png'


import chorizo from '../../Assets/HouseSpecialty/chorizo.png'
import delacasa from '../../Assets/HouseSpecialty/delacasa.png'
import madrilena from '../../Assets/HouseSpecialty/madrilena.png'
import negra from '../../Assets/HouseSpecialty/negra.png'
import flanCaramelo from '../../Assets/HouseSpecialty/flanCaramelo.png'
import lengua from '../../Assets/HouseSpecialty/lengua.png'

import './HouseSpecialty.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
function HouseSpecialty() {
    
    const { t } = useTranslation();

    function handleClick(lang) {
        i18next.changeLanguage(lang)
    }
    const [specialChorizo, setSpecialChorizo] = useState(false);
    const [specialMadrilena, setSpecialMadrilena] = useState(false);
    const [specialNegra, setSpecialNegra] = useState(false);
    const [specialDelacasa, setSpecialDelacasa] = useState(false);
    const [specialFlan, setSpecialFlan] = useState(false);
    const [specialLengua, setSpecialLengua] = useState(false);
    return(<>
        
        <div className='houseSpecialtyContainer'>

            <div className='houseSpecialtyHeader'>
                <p>House Specialty</p>
            </div>
                <Row className='justify-content-md-center mb-3 specialRow'>
                    <Col md={2}>
                        <Card className='cardSpecialty' onClick={() => setSpecialChorizo(true)}>
                            <Card.Img className='cardImageSpecialty' orientation="top" src={chorizo} />
                            <Card.Body>
                                <Card.Text className='cardTextSpecialty'>
                                    SPANISH CHORIZO
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Modal className='modalForm' backdrop="static" show={specialChorizo} onHide={() => setSpecialChorizo(false)} closeButton>
                        <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setSpecialChorizo(false)}/>
                        <img className='modalImage' src={chorizo}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('tapasTitle.4')}</b></p>        
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translateSpecialty'>Translate:</p>
                                        </Col>
                                        <Col md={2}> 
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col> 
                                    </Row>
                                    
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                          
                                </Col>
                            </Row>
                            <Row>
                                <Col> 
                                    <Row>
                                        <p>{t('tapasDescription.4')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱250.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$4.24</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€4.34</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                    </Col>
                    <Col md={2}>
                        <Card className='cardSpecialty' onClick={() => setSpecialDelacasa(true)}>
                            <Card.Img  className='cardImageSpecialty' orientation="top" src={delacasa} />
                            <Card.Body>
                                <Card.Text className='cardTextSpecialty'>
                                    PAELLA DE LA CASA
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Modal className='modalForm' backdrop="static" show={specialDelacasa} onHide={() => setSpecialDelacasa(false)} closeButton>
                        <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setSpecialDelacasa(false)}/>
                        <img className='modalImage' src={delacasa}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('paellasTitle.1')}</b></p>        
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translateSpecialty'>Translate:</p>
                                        </Col>
                                        <Col md={2}> 
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col> 
                                    </Row>
                                    
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                          
                                </Col>
                            </Row>
                            <Row>
                                <Col> 
                                    <Row>
                                        <Row>
                                            <p>{t('paellasDescription.1')}</p>
                                        </Row>
                                        <Row className='pax'>
                                            <Col className='col-10'>
                                                <p>{t('paellasDescription.7')}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='col-4 menuThree'>
                                                <p className='priceName'><b>₱1,850.00</b></p>
                                            </Col>
                                            <Col className='col-3 menuTwo'>
                                                <p className='priceName'><b>$31.45</b></p>
                                            </Col>
                                            <Col className='col-3 menuTwo'>
                                                <p className='priceName'><b>€32.13</b></p>
                                            </Col>
                                        </Row>
                                    </Row>
                                    <Row>
                                        <Row className='pax'>
                                            <Col className='col-10'>
                                                <p>{t('paellasDescription.8')}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='col-4 menuThree'>
                                                <p className='priceName'><b>₱370.00</b></p>
                                            </Col>
                                            <Col className='col-3 menuTwo'>
                                                <p className='priceName'><b>$6.29</b></p>
                                            </Col>
                                            <Col className='col-3 menuTwo'>
                                                <p className='priceName'><b>€6.43</b></p>
                                            </Col>
                                        </Row>
                                    </Row>
                                    <Row>
                                        <p>{t('paellasDescription.6')}</p>
                                    </Row>
                                    
                                    
                                    
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                    </Col>
                    <Col md={2}>
                        <Card className='cardSpecialty' onClick={() => setSpecialMadrilena(true)}>
                            <Card.Img className='cardImageSpecialty' orientation="top" src={madrilena} />
                            <Card.Body>
                                <Card.Text className='cardTextSpecialty'>
                                    CALLOS A LA  MADRILEÑA
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Modal className='modalForm' backdrop="static" show={specialMadrilena} onHide={() => setSpecialMadrilena(false)} closeButton>
                        <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setSpecialMadrilena(false)}/>
                        <img className='modalImage' src={madrilena}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('carnesTitle.3')}</b></p>        
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translateSpecialty'>Translate:</p>
                                        </Col>
                                        <Col md={2}> 
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col> 
                                    </Row>
                                    
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                          
                                </Col>
                            </Row>
                            <Row>
                                <Col> 
                                    <Row>
                                        <p>{t('carnesDescription.3')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName '><b>₱350.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$5.94</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€6.08</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                    </Col>
                </Row>
                <Row className='justify-content-md-center mb-4 specialRow'>
                    <Col md={2}>
                        <Card className='cardSpecialty' onClick={() => setSpecialNegra(true)}>
                            <Card.Img className='cardImageSpecialty' orientation="top" src={negra} />
                            <Card.Body>
                                <Card.Text className='cardTextSpecialty'>
                                    PAELLA NEGRA
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Modal className='modalForm' backdrop="static" show={specialNegra} onHide={() => setSpecialNegra(false)} closeButton>
                        <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setSpecialNegra(false)}/>
                        <img className='modalImage' src={negra}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('paellasTitle.4')}</b></p>        
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translateSpecialty'>Translate:</p>
                                        </Col>
                                        <Col md={2}> 
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col> 
                                    </Row>
                                    <Row>
                                        <p>{t('paellasDescription.4')}</p>
                                    </Row>
                                    
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                          
                                </Col>
                            </Row>
                            <Row>
                                <Col> 
                                    <Row>
                                        <Row className='pax'>
                                            <Col className='col-10'>
                                                <p>{t('paellasDescription.7')}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='col-4 menuThree'>
                                                <p className='priceName'><b>₱1,580.00</b></p>
                                            </Col>
                                            <Col className='col-3 menuTwo'>
                                                <p className='priceName'><b>$26.84</b></p>
                                            </Col>
                                            <Col className='col-3 menuTwo'>
                                                <p className='priceName'><b>€27.44</b></p>
                                            </Col>
                                        </Row>
                                    </Row>
                                    <Row>
                                        <Row className='pax'>
                                            <Col className='col-10'>
                                                <p>{t('paellasDescription.8')}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='col-4 menuThree'>
                                                <p className='priceName'><b>₱350.00</b></p>
                                            </Col>
                                            <Col className='col-3 menuTwo'>
                                                <p className='priceName'><b>$5.95</b></p>
                                            </Col>
                                            <Col className='col-3 menuTwo'>
                                                <p className='priceName'><b>€6.08</b></p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <p>
                                                {t('paellasDescription.6')}
                                            </p>
                                        </Row>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                    </Col>
                    <Col md={2}>
                        <Card className='cardSpecialty' onClick={() => setSpecialLengua(true)}>
                            <Card.Img  className='cardImageSpecialty' orientation="top" src={lengua} />
                            <Card.Body>
                                <Card.Text className='cardTextSpecialty'>
                                    LENGUA ESTOFADA
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Modal className='modalForm' backdrop="static" show={specialLengua} onHide={() => setSpecialLengua(false)} closeButton>
                        <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setSpecialLengua(false)}/>
                        <img className='modalImage' src={lengua}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('carnesTitle.1')}</b></p>        
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translateSpecialty'>Translate:</p>
                                        </Col>
                                        <Col md={2}> 
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col> 
                                    </Row>
                                    <Row>
                                        <p>{t('carnesDescription.1')}</p>
                                    </Row>
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                          
                                </Col>
                            </Row>
                            <Row>
                                <Col> 
                                    
                                    <Row>
                                        <Col className='col-4 menuThree'>
                                            <p className='priceName'><b>₱1500.00</b></p>
                                        </Col>
                                        <Col className='col-3 menuTwo'>
                                            <p className='priceName'><b>$25.46</b></p>
                                        </Col>
                                        <Col className='col-3 menuTwo'>
                                            <p className='priceName'><b>€26.06</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                    </Col>
                    <Col md={2}>
                        <Card className='cardSpecialty' onClick={() => setSpecialFlan(true)}>
                            <Card.Img className='cardImageSpecialty' orientation="top" src={flanCaramelo} />
                            <Card.Body>
                                <Card.Text className='cardTextSpecialty'>
                                    FLAN AL CARAMELO
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Modal className='modalForm' backdrop="static" show={specialFlan} onHide={() => setSpecialFlan(false)} closeButton>
                        <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setSpecialFlan(false)}/>
                        <img className='modalImage' src={flanCaramelo}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('postresTitle.1')}</b></p>        
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translateSpecialty'>Translate:</p>
                                        </Col>
                                        <Col md={2}> 
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col> 
                                    </Row>
                                    <Row>
                                        <p>{t('postresDescription.1')}</p>
                                    </Row>
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                          
                                </Col>
                            </Row>
                            <Row>
                                <Col> 
                                   
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱65.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$1.10</b></p>
                                        </Col> 
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€1.13</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                    </Col>
                </Row>
        </div>
    </>)
}

export default HouseSpecialty