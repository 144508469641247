import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './AboutUs.css'
import chefIpar from '../../Assets/chefIpar.png'
import empty from '../../Assets/Tapas/Specialty1.jpg'

function AboutUS() {

    return(<>

        <div className='about-us-container'>
            <div className='aboutUsHeader'>
                <p>About Us</p>
            </div>
            <Row className='aboutRow'>
                <Col className='col-4 colFour'>
                    <div className='divName'>
                        <img 
                            className="aboutImageFirst"
                            src={chefIpar}
                            alt="Chef"/>
                    </div>
                </Col>
                <Col className='col-2 colTwo'>
                    <Row className='aboutImageCol2'>
                        <Col>
                            <img 
                                className="aboutImageSecond"
                                src={empty}
                                alt="Chef"/>
                        </Col>
                        <Col>
                        <img 
                            className="aboutImageThird"
                            src={empty}
                            alt="Chef"/>
                        </Col>
                    </Row>
                    
                </Col>
                <Col className='col-6 colSix'>
                    <p className="aboutDescription">
                        Chef Ipar Miranda creates from pure passion. He began a predestined love affair with Spanish cuisine from a young age—growing up to the heady smells of garlic sautéing in olive oil, Spanish dishes steaming in plates, and in the old family kitchen, where the heat happened, was where he was to be constantly found.
                    </p>
                    <p className="aboutDescription">
                        A course in Business Management was a practical—but largely preparatory—detour from his true calling. He enrolled at Beverly Hill’s Epicurean School of Culinary Arts for a crash course in French culinary arts soon after in 2000. This, besides being Ipar’s first formal foray into the world of fine cuisine, was also his proverbial first step toward realizing a boyhood dream.
                    </p>
                    <p className="aboutDescription">              
                        Spain beckoned, and shortly after he completed his course in the U.S., Ipar proceeded to IES San Jose in Cuenca, Castilla-La Mancha. Symbolically the home of the classical quester Don Quixote, La Mancha became Ipar’s home for three years as he apprenticed and continued to refine his beloved craft. His travels later took him to the Basque region of Zarautz,a seaside town known for its gastronomic traditions, where he trained under the legendary celebrity chef Karlos Arguiñano. Chef Ipar returned home to Cebu in 2006, bringing Spain with him on a platter—and the rest, as they say, is history when the city’s first authentic Spanish restaurant and tapas bar welcomed the curious Cebuano crowds.
                    </p>
                </Col>
            </Row>
            <Row className='aboutUsFooter '>
                <Row className='aboutUsFirstFooter'>
                    <p>
                        Ipar O. Miranda
                    </p>
                </Row>
                <Row className='aboutUsSecondFooter'>
                    <p>
                        Chef & Owner<br/>
                        Cooking with soul Dreams and the Finest Ingredients
                    </p>
                </Row>
            </Row>
        
        </div>
            
           
            
       
    
    </>)
}
export default AboutUS