import React,{useState} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Footer from '../../components/Footer/Footer'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';
import { Card, Modal} from 'react-bootstrap';
import { CircleFlag } from 'react-circle-flags'
import grab from '../../Assets/Modal/GrabFood.png'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import Menu from '../../Pages/Menu/Menu';
import Ensaladas from "../../components/Ensaladas/Ensaladas"
import Sopas from '../../components/Sopas/Sopas';
import Paellas from '../../components/Paellas/Paellas';
import Pescados from '../../components/Pescados/Pescados';
import Carnes from '../../components/Carnes/Carnes';
import Postres from '../Postres/Postres';
import SideOrders from '../SideOrders/SideOrders';

import empty from '../../Assets/Tapas/Specialty1.jpg'
import polloSalsa from '../../Assets/Aves/polloSalsa.png'

import "./Aves.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'


function Aves() {
    const { t } = useTranslation();

    function handleClick(lang) {
    i18next.changeLanguage(lang)
    }

    const [avesChilindron, setChilindron] = useState(false);
    const [avesPolloAjillo, setAvesPolloAjillo] = useState(false);
    const [avesSalsa, setAvesSalsa] = useState(false);
    const [avesPolloAsado, setPolloAsado] = useState(false);
    const [avesAlLimon, setAlLimon] = useState(false);
    const [avesAlAjillo, setAvesAlAjillo] = useState(false);
  return (
    <div className='menuContainer'>
      <div className='menuHeader'>
          <p>Our Menu</p>
      </div>
      <div className='options-container'>
        <div className='options-wrapper'>

          <div>
            <nav className='dropdownNav'>
              {[DropdownButton].map((DropdownType, idx) => (
                <DropdownType
                  as={ButtonGroup}
                  key={idx}
                  id={`dropdown-button-drop`}
                  size="sm"
                  variant="dark"
                  menuVariant="transparent"
                  title="Aves"
                >
                  <Dropdown.Item eventKey="1"><Link to="/menu" className='menu-option'>Tapas</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="2"><Link to="/ensaladas" className='menu-option' name="ensaladas">Ensaladas</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="3"><Link to="/sopas" className='menu-option' name="sopas">Sopas</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="4"><Link to="/paellas" className='menu-option' name="paellas">Paellas</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="5"><Link to="/pescados" className='menu-option'>Pescados y Mariscos</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="6"><Link to="/carnes" className='menu-option'>Carnes</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="7"><Link className='menuPicked'>Aves</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="8"><Link to="/postres" className='menu-option' name="postres">Postres</Link></Dropdown.Item>
                  <Dropdown.Item eventKey=""><Link to="/sideOrders" className='menu-option' name="side">Side Orders</Link></Dropdown.Item>
                </DropdownType>
              ))}
            </nav>
            <nav className="navbar">
              <Link to="/menu" className='menu-option'>Tapas</Link>
              <Link to="/ensaladas" className='menu-option'>Ensaladas</Link>
              <Link to="/sopas" className='menu-option'>Sopas</Link>
              <Link to="/paellas" className='menu-option'>Paellas</Link>
              <Link to="/pescados" className='menu-option'>Pescados y Mariscos</Link>
              <Link to="/carnes" className='menu-option'>Carnes</Link>
              <Link className='menuPicked'>Aves</Link>
              <Link to="/postres" className='menu-option'>Postres</Link>
              <Link to="/sideOrders" className='menu-option'>Side Orders</Link>

            </nav>
              <Routes>
              <Route path="/menu" element={<Menu />} />
                <Route path="/ensaladas/*" element={<Ensaladas />} />
                <Route path="/sopas" element={<Sopas/>} />
                <Route path="/paellas" element={<Paellas/>} />
                <Route path="/pescados" element={<Pescados/>} />
                <Route path="/carnes" element={<Carnes/>} />
                <Route path="/postres" element={<Postres/>} />
                <Route path="/sideOrders" element={<SideOrders/>} />
              </Routes>
          </div>


        </div>
      </div>
      <Row className='avesRow'>
            <Col sm={'auto'} style={{ width: "18vw" }}>
                <Card onClick={() => setChilindron(true)}className='card'>
                    <Card.Img className='cardImage'variant="top" src={empty} />
                    <Card.Body>
                    <Card.Title className='cardTitle'>POLLO AL CHILINDRON</Card.Title>
                    </Card.Body>
                </Card>
                <Modal className='modalForm' backdrop="static" show={avesChilindron} onHide={() => setChilindron(false)} closeButton>
                <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setChilindron(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('avesTitle.1')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('avesDescription.1')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱280.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$4.75</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€4.86</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
            </Col>
            <Col sm={'auto'} style={{ width: "18vw" }}>
                <Card onClick={() => setAvesPolloAjillo(true)}className='card'>
                    <Card.Img className='cardImage'variant="top" src={empty} />
                    <Card.Body>
                    <Card.Title className='cardTitle'>POLLO AL AJILLO</Card.Title>
                    </Card.Body>
                </Card>
                <Modal className='modalForm' backdrop="static" show={avesPolloAjillo} onHide={() => setAvesPolloAjillo(false)} closeButton>
                <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setAvesPolloAjillo(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('avesTitle.2')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('avesDescription.2')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱260.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$4.41</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€4.52</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
            </Col>
            <Col className='avesCol3' sm={'auto'} style={{ width: "18vw" }}>
                <Card onClick={() => setAvesSalsa(true)}className='card'>
                    <Card.Img className='cardImage'variant="top" src={polloSalsa} />
                    <Card.Body>
                    <Card.Title className='cardTitle'>POLLO EN SALSA </Card.Title>
                    </Card.Body>
                </Card>
                <Modal className='modalForm' backdrop="static" show={avesSalsa} onHide={() => setAvesSalsa(false)} closeButton>
                <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setAvesSalsa(false)}/>
                        <img className='modalImage' src={polloSalsa}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('avesTitle.3')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('avesDescription.3')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱280.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$4.75</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€4.86</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
            </Col>
            <Col className='avesCol4' sm={'auto'} style={{ width: "18vw" }}>
                <Card onClick={() => setPolloAsado(true)}className='card'>
                    <Card.Img className='cardImage'variant="top" src={empty} />
                    <Card.Body>
                    <Card.Title className='cardTitle'>POLLO ASADO AL HORNO </Card.Title>
                    </Card.Body>
                </Card>
                <Modal className='modalForm' backdrop="static" show={avesPolloAsado} onHide={() => setPolloAsado(false)} closeButton>
                <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setPolloAsado(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('avesTitle.4')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('avesDescription.4')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱850.00</b></p>
                                        </Col>
                                        <Col className='col-3 menuTwo'>
                                            <p className='priceName'><b>$14.43</b></p>
                                        </Col>
                                        <Col className='col-3 menuTwo'>
                                            <p className='priceName'><b>€14.77</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='menuDescription'>{t('avesDescription.7')}</p>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
            </Col>
            <Col className='avesCol4' sm={'auto'} style={{ width: "18vw" }}>
                <Card onClick={() => setAlLimon(true)}className='card'>
                    <Card.Img className='cardImage'variant="top" src={empty} />
                    <Card.Body>
                    <Card.Title className='cardTitle'>AL LIMON </Card.Title>
                    </Card.Body>
                </Card>
                <Modal className='modalForm' backdrop="static" show={avesAlLimon} onHide={() => setAlLimon(false)} closeButton>
                <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setAlLimon(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('avesTitle.5')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('avesDescription.5')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱850.00</b></p>
                                        </Col>
                                        <Col className='col-3 menuTwo'>
                                            <p className='priceName'><b>$14.43</b></p>
                                        </Col>
                                        <Col className='col-3 menuTwo'>
                                            <p className='priceName'><b>€14.77</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='menuDescription'>{t('avesDescription.7')}</p>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
            </Col>
            <Col className='avesCol4' sm={'auto'} style={{ width: "18vw" }}>
                <Card onClick={() => setAvesAlAjillo(true)}className='card'>
                    <Card.Img className='cardImage'variant="top" src={empty} />
                    <Card.Body>
                    <Card.Title className='cardTitle'>AL AJILLO</Card.Title>
                    </Card.Body>
                </Card>
                <Modal className='modalForm' backdrop="static" show={avesAlAjillo} onHide={() => setAvesAlAjillo(false)} closeButton>
                <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setAvesAlAjillo(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('avesTitle.6')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('avesDescription.6')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱850.00</b></p>
                                        </Col>
                                        <Col className='col-3 menuTwo'>
                                            <p className='priceName'><b>$14.43</b></p>
                                        </Col>
                                        <Col className='col-3 menuTwo'>
                                            <p className='priceName'><b>€14.77</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='menuDescription'>{t('avesDescription.7')}</p>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
            </Col>
        </Row>

        <Footer/>
      </div>
  )
}

export default Aves