import React,{useState} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Footer from '../../components/Footer/Footer'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';
import { Card, Modal} from 'react-bootstrap';
import { CircleFlag } from 'react-circle-flags'
import grab from '../../Assets/Modal/GrabFood.png'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import Menu from '../../Pages/Menu/Menu';
import Ensaladas from "../../components/Ensaladas/Ensaladas"
import Sopas from '../../components/Sopas/Sopas';
import Paellas from '../../components/Paellas/Paellas';
import Pescados from '../../components/Pescados/Pescados';
import Aves from '../Aves/Aves';
import Postres from '../Postres/Postres';
import SideOrders from '../SideOrders/SideOrders';

import empty from '../../Assets/Tapas/Specialty1.jpg'
import decerdo from '../../Assets/Carnes/decerdo.png'
import madrilena from '../../Assets/Carnes/madrilena.png'
import solomillo from '../../Assets/Carnes/solomillo.png'
import lengua from '../../Assets/Carnes/lengua.png'


import "./Carnes.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'

function Carnes() {
  const { t } = useTranslation();

  function handleClick(lang) {
    i18next.changeLanguage(lang)
  }

  const [carnesLengua, setLengua] = useState(false);
  const [carnesSolomillo, setSolomillo] = useState(false);
  const [carnesMadrilena, setMadrilena] = useState(false);
  const [carnesChuletas, setChuletas] = useState(false);
  const [carnesChuleton, setChuleton] = useState(false);
  return (
    <div className='menuContainer'>
      <div className='menuHeader'>
          <p>Our Menu</p>
      </div>
      <div className='options-container'>
        <div className='options-wrapper'>

          <div>
            <nav className='dropdownNav'>
              {[DropdownButton].map((DropdownType, idx) => (
                <DropdownType
                  as={ButtonGroup}
                  key={idx}
                  id={`dropdown-button-drop`}
                  size="sm"
                  variant="dark"
                  menuVariant="transparent"
                  title="Carnes"
                >
                  <Dropdown.Item eventKey="1"><Link to="/menu" className='menu-option'>Tapas</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="2"><Link to="/ensaladas" className='menu-option' name="ensaladas">Ensaladas</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="3"><Link to="/sopas" className='menu-option' name="sopas">Sopas</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="4"><Link to="/paellas" className='menu-option' name="paellas">Paellas</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="5"><Link to="/pescados" className='menu-option'>Pescados y Mariscos</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="6"><Link className='menuPicked'>Carnes</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="7"><Link to="/aves" className='menu-option' name="aves">Aves</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="8"><Link to="/postres" className='menu-option' name="postres">Postres</Link></Dropdown.Item>
                  <Dropdown.Item eventKey=""><Link to="/sideOrders" className='menu-option' name="side">Side Orders</Link></Dropdown.Item>
                </DropdownType>
              ))}
            </nav>
            <nav className="navbar">
              <Link to="/menu" className='menu-option'>Tapas</Link>
              <Link to="/ensaladas" className='menu-option'>Ensaladas</Link>
              <Link to="/sopas" className='menu-option'>Sopas</Link>
              <Link to="/paellas" className='menu-option'>Paellas</Link>
              <Link to="/pescados" className='menu-option'>Pescados y Mariscos</Link>
              <Link className='menuPicked'>Carnes</Link>
              <Link to="/aves" className='menu-option'>Aves</Link>
              <Link to="/postres" className='menu-option'>Postres</Link>
              <Link to="/sideOrders" className='menu-option'>Side Orders</Link>

            </nav>
              <Routes>
                <Route path="/menu" element={<Menu/>} />
                <Route path="/ensaladas/*" element={<Ensaladas />} />
                <Route path="/sopas" element={<Sopas/>} />
                <Route path="/paellas" element={<Paellas/>} />
                <Route path="/pescados" element={<Pescados/>} />
                <Route path="/aves" element={<Aves/>} />
                <Route path="/postres" element={<Postres/>} />
                <Route path="/sideOrders" element={<SideOrders/>} />
              </Routes>
          </div>


        </div>
      </div>
      <Row className='carnesRow'>
            <Col sm={'auto'} style={{ width: "18vw" }}>
                <Card onClick={() => setLengua(true)}className='card'>
                    <Card.Img className='cardImage'variant="top" src={lengua} />
                    <Card.Body>
                    <Card.Title className='cardTitle'>LENGUA ESTOFADA </Card.Title>
                    </Card.Body>
                </Card>
                <Modal className='modalForm' backdrop="static" show={carnesLengua} onHide={() => setLengua(false)} closeButton>
                <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setLengua(false)}/>
                        <img className='modalImage' src={lengua}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('carnesTitle.1')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('carnesDescription.1')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-4 menuThree'>
                                            <p className='priceName'><b>₱1500.00</b></p>
                                        </Col>
                                        <Col className='col-3 menuTwo'>
                                            <p className='priceName'><b>$25.46</b></p>
                                        </Col>
                                        <Col className='col-3 menuTwo'>
                                            <p className='priceName'><b>€26.06</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
            </Col>
            <Col sm={'auto'} style={{ width: "18vw" }}>
                <Card onClick={() => setSolomillo(true)}className='card'>
                    <Card.Img className='cardImage'variant="top" src={solomillo} />
                    <Card.Body>
                    <Card.Title className='cardTitle'>SOLOMILLO A LO POBRE  </Card.Title>
                    </Card.Body>
                </Card>
                <Modal className='modalForm' backdrop="static" show={carnesSolomillo} onHide={() => setSolomillo(false)} closeButton>
                <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setSolomillo(false)}/>
                        <img className='modalImage' src={solomillo}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('carnesTitle.2')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                   
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('carnesDescription.2')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱470.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$7.98</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€8.17</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
            </Col>
            <Col className='carnesCol3' sm={'auto'} style={{ width: "18vw" }}>
                <Card onClick={() => setMadrilena(true)}className='card'>
                    <Card.Img className='cardImage'variant="top" src={madrilena} />
                    <Card.Body>
                    <Card.Title className='cardTitle'>CALLOS A LA MADRILEÑA  <br></br>CON GULAS</Card.Title>
                    </Card.Body>
                </Card>
                <Modal className='modalForm' backdrop="static" show={carnesMadrilena} onHide={() => setMadrilena(false)} closeButton>
                <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setMadrilena(false)}/>
                        <img className='modalImage' src={madrilena}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('carnesTitle.3')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('carnesDescription.3')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱350.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$5.94</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€6.08</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
            </Col>
            <Col className='carnesCol4' sm={'auto'} style={{ width: "18vw" }}>
                <Card onClick={() => setChuletas(true)}className='card'>
                    <Card.Img className='cardImage'variant="top" src={decerdo} />
                    <Card.Body className='lastCarnesMenuBody'>
                    <Card.Title className='cardTitle'>CHULETAS DE CERDO<br></br>(AL AJILLO / EMPANADAS)</Card.Title>
                    </Card.Body>
                </Card>
                <Modal className='modalForm' backdrop="static" show={carnesChuletas} onHide={() => setChuletas(false)} closeButton>
                <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setChuletas(false)}/>
                        <img className='modalImage' src={decerdo}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('carnesTitle.4')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('carnesDescription.4')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱370.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$6.28</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€6.43</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
            </Col>
            <Col className='carnesCol5' sm={'auto'} style={{ width: "18vw" }}>
                <Card onClick={() => setChuleton(true)}className='card'>
                    <Card.Img className='cardImage'variant="top" src={empty} />
                    <Card.Body className='lastCarnesMenuBody'>
                    <Card.Title className='cardTitle'>CHULETON ASADO </Card.Title>
                    </Card.Body>
                </Card>
                <Modal className='modalForm' backdrop="static" show={carnesChuleton} onHide={() => setChuleton(false)} closeButton>
                <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setChuleton(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('carnesTitle.5')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('carnesDescription.5')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱400.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$6.79</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€6.95</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='menuDescription'>{t('carnesDescription.6')}</p>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
            </Col>
        </Row>
        <Footer/>

      </div>
  )
}

export default Carnes