import React,{useState} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Footer from '../../components/Footer/Footer'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';
import { Card, Modal} from 'react-bootstrap';
import { CircleFlag } from 'react-circle-flags'
import grab from '../../Assets/Modal/GrabFood.png'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import Menu from '../../Pages/Menu/Menu';
import Ensaladas from "../Ensaladas/Ensaladas"
import Sopas from '../Sopas/Sopas';
import Paellas from '../Paellas/Paellas';
import Pescados from '../Pescados/Pescados';
import Carnes from '../Carnes/Carnes';
import Aves from '../Aves/Aves';
import Postres from '../Postres/Postres';
import empty from '../../Assets/Tapas/Specialty1.jpg'


import "./SideOrders.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'


function SideOrders() {
    const { t } = useTranslation();

    function handleClick(lang) {
    i18next.changeLanguage(lang)
    }

    const [sidePlainRice, setPlainRice] = useState(false);
    const [sideGarlicRice, setGarlicRice] = useState(false);
    const [sidePlainBread, setPlainBread] = useState(false);
    const [sideGarlicBread, setGarlicBread] = useState(false);
    const [sideMashedPotato, setMashedPotato] = useState(false);
    const [sideFrenchFries, setFrenchFries] = useState(false);
  return (
    <div className='menuContainer'>
      <div className='menuHeader'>
          <p>Our Menu</p>
      </div>
      <div className='options-container'>
        <div className='options-wrapper'>

          <div>
            <nav className='dropdownNav'>
              {[DropdownButton].map((DropdownType, idx) => (
                <DropdownType
                  as={ButtonGroup}
                  key={idx}
                  id={`dropdown-button-drop`}
                  size="sm"
                  variant="dark"
                  menuVariant="transparent"
                  title="Side Orders"
                >
                  <Dropdown.Item eventKey="1"><Link to="/menu" className='menu-option'>Tapas</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="2"><Link to="/ensaladas" className='menu-option' name="ensaladas">Ensaladas</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="3"><Link to="/sopas" className='menu-option' name="sopas">Sopas</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="4"><Link to="/paellas" className='menu-option' name="paellas">Paellas</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="5"><Link to="/pescados" className='menu-option'>Pescados y Mariscos</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="6"><Link to="/carnes" className='menu-option'>Carnes</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="7"><Link to="/aves" className='menu-option'>Aves</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="8"><Link to="/postres" className='menu-option'>Postres</Link></Dropdown.Item>
                  <Dropdown.Item eventKey=""><Link className='menuPicked'>Side Orders</Link></Dropdown.Item>
                </DropdownType>
              ))}
            </nav>
            <nav className="navbar">
              <Link to="/menu" className='menu-option'>Tapas</Link>
              <Link to="/ensaladas" className='menu-option'>Ensaladas</Link>
              <Link to="/sopas" className='menu-option'>Sopas</Link>
              <Link to="/paellas" className='menu-option'>Paellas</Link>
              <Link to="/pescados" className='menu-option'>Pescados y Mariscos</Link>
              <Link to="/carnes" className='menu-option'>Carnes</Link>
              <Link to="/aves" className='menu-option'>Aves</Link>
              <Link to="/postres" className='menu-option'>Postres</Link>
              <Link className='menuPicked'>Side Orders</Link>

            </nav>
              <Routes>
                <Route path="/menu" element={<Menu />} />
                <Route path="/ensaladas/*" element={<Ensaladas />} />
                <Route path="/sopas" element={<Sopas/>} />
                <Route path="/paellas" element={<Paellas/>} />
                <Route path="/pescados" element={<Pescados/>} />
                <Route path="/carnes" element={<Carnes/>} />
                <Route path="/aves" element={<Aves/>} />
                <Route path="/postres" element={<Postres/>} />

              </Routes>
          </div>


        </div>
      </div>
      <Row className='orderRow'>
            <Col sm={'auto'} style={{ width: "18vw" }}>
                <Card onClick={() => setPlainRice(true)}className='card'>
                    <Card.Img className='cardImage'variant="top" src={empty} />
                    <Card.Body>
                    <Card.Title className='cardTitle'>PLAIN RICE </Card.Title>
                    </Card.Body>
                </Card>
                <Modal className='modalForm' backdrop="static" show={sidePlainRice} onHide={() => setPlainRice(false)} closeButton>
                <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setPlainRice(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('sideOrderTitle.1')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱35.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$0.59</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€0.61</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
            </Col>
            <Col sm={'auto'} style={{ width: "18vw" }}>
                <Card onClick={() => setGarlicRice(true)}className='card'>
                    <Card.Img className='cardImage'variant="top" src={empty} />
                    <Card.Body>
                    <Card.Title className='cardTitle'>GARLIC RICE</Card.Title>
                    </Card.Body>
                </Card>
                <Modal className='modalForm' backdrop="static" show={sideGarlicRice} onHide={() => setGarlicRice(false)} closeButton>
                <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setGarlicRice(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('sideOrderTitle.2')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱400.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$0.93</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€0.96</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
            </Col>
            <Col className='sideCol3' sm={'auto'} style={{ width: "18vw" }}>
                <Card onClick={() => setPlainBread(true)}className='card'>
                    <Card.Img className='cardImage'variant="top" src={empty} />
                    <Card.Body>
                    <Card.Title className='cardTitle'>PLAIN BREAD</Card.Title>
                    </Card.Body>
                </Card>
                <Modal className='modalForm' backdrop="static" show={sidePlainBread} onHide={() => setPlainBread(false)} closeButton>
                <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setPlainBread(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('sideOrderTitle.3')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱75.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$1.27</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€1.30</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
            </Col>
            <Col className='sideCol4' sm={'auto'} style={{ width: "18vw" }}>
                <Card onClick={() => setGarlicBread(true)}className='card'>
                    <Card.Img className='cardImage'variant="top" src={empty} />
                    <Card.Body>
                    <Card.Title className='cardTitle'>GARLIC BREAD</Card.Title>
                    </Card.Body>
                </Card>
                <Modal className='modalForm' backdrop="static" show={sideGarlicBread} onHide={() => setGarlicBread(false)} closeButton>
                <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setGarlicBread(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('sideOrderTitle.4')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱130.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$2.21</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€2.26</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
            </Col>
            <Col className='sideCol4' sm={'auto'} style={{ width: "18vw" }}>
                <Card onClick={() => setMashedPotato(true)}className='card'>
                    <Card.Img className='cardImage'variant="top" src={empty} />
                    <Card.Body>
                    <Card.Title className='cardTitle'>MASHED POTATO </Card.Title>
                    </Card.Body>
                </Card>
                <Modal className='modalForm' backdrop="static" show={sideMashedPotato} onHide={() => setMashedPotato(false)} closeButton>
                <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setMashedPotato(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('sideOrderTitle.5')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱85.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$1.44</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€1.48</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
            </Col>
            <Col className='sideCol4' sm={'auto'} style={{ width: "18vw" }}>
                <Card onClick={() => setFrenchFries(true)}className='card'>
                    <Card.Img className='cardImage'variant="top" src={empty} />
                    <Card.Body>
                    <Card.Title className='cardTitle'>FRENCH FRIES</Card.Title>
                    </Card.Body>
                </Card>
                <Modal className='modalForm' backdrop="static" show={sideFrenchFries} onHide={() => setFrenchFries(false)} closeButton>
                <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setFrenchFries(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('sideOrderTitle.6')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱65.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$1.10</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€1.13</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
            </Col>
        </Row>
        <Footer/>

      </div>
  )
}

export default SideOrders