import React, {useState,useEffect} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faWarehouse } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { faMobileRetro } from '@fortawesome/free-solid-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import "./Contact.css"
import '../../components/Loader/loader.css'

function Contact() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.body.style.background = "black";
    if (document.readyState === "complete") {
        setTimeout(function() {
            setLoading(true);
            document.body.style.background = "white";
        }, 2000);
    }
  }, []);

  {
      (function(){
          window.addEventListener('load', function() {
              setLoading(true);
          });
      })();
  } 

    {
        return !loading? (
            <div className="loader">
            <span className="hour"></span>
            <span className="min"></span>
            <span className="circel"></span>
            </div>
        ):(
    <div className='contactContainer'>
      <div className='contactHeaders'>
          <h1 className='contactH1'>Contact Us</h1>
      </div>
      <div className='contactIcon'>
       <Row className='contactRow'>
          <Col className='contactSecondCol'>
          <a className='linkName' target="_blank" href='https://g.page/iparscebu?share'>
            <Row className='contactFirstRow'>
              <Col className='col-2'>
                
                  <FontAwesomeIcon className='icon' icon={faMapLocationDot}/>
                
              </Col>
              <Col className='col-6'>
                <p className='contactTitle'><b>Address</b><br></br>157 F.Ramos Street, Cebu City, Philippines, 6000</p>
              </Col>
            </Row>
            </a>
            <a className='linkName' target="_blank" href='mailto: contact@ipars.com.ph'>
            <Row className='contactFirstRow'>
              <Col className='col-2'>
                  <FontAwesomeIcon className='icon' icon={faEnvelope}/>
              </Col>
              <Col className='col-6'>
                <p className='contactTitle'><b>Email</b>
                <br></br>contact@ipars.com.ph
                </p>
              </Col>
            </Row>
            </a>
            <a className='linkName' target="_blank" href='https://www.facebook.com/IparsCebu/'>
            <Row className='contactFirstRow'>
              <Col className='col-2'>
                  <FontAwesomeIcon className='icon' icon={faFacebook}/>
              </Col>
              <Col className='col-6'>
                <p className='contactTitle'><b>Facebook</b><br></br>@IparsCebu</p>
              </Col>
            </Row>
            </a>
            <a className='linkName' target="_blank" href='https://www.instagram.com/iparsrestaurantecebu/'>
            <Row className='contactFirstRow'>
              <Col className='col-2'>
                  <FontAwesomeIcon className='icon' icon={faInstagram}/>
              </Col>
              <Col className='col-6'>
                <p className='contactTitle'><b>Instagram</b><br></br>@iparsrestaurantecebu</p>
              </Col>
            </Row>
            </a>
            
          </Col>

          <Col className='contactSecondCol'>
            <Row className='contactSecondRow'>
              <Col className='col-2'>
                <FontAwesomeIcon className='icon' icon={faWarehouse}/>
              </Col>
              <Col className='col-6 '>
                <p className='contactTitle'><b>Capacity</b><br></br>40 pax<br></br>5 parking slots</p>
                </Col>
            </Row>
            <Row className='contactSecondRow'>
              <Col className='col-2'>
                <FontAwesomeIcon className='icon' icon={faClock}/>
              </Col>
              <Col className='col-6'>
                <p className='contactTitle'><b>Opening Hours</b><br></br>Tuesday to Sunday<br></br>10:00 AM - 2:00 PM<br></br>6:00 PM - 10:00 PM</p>
              </Col>
            </Row>
            <a>
            <Row className='contactSecondRow'>
              <Col className='col-2'>
                <FontAwesomeIcon className='icon' icon={faPhone}/>
              </Col>
              <Col className='col-6'>
                <p className='contactTitle'><b>Landline</b>
                <br></br><a className='linkName' target="_blank" href='tel: (032)2554727'>(032)2554727</a>
                <br></br><a className='linkName' target="_blank" href='tel: (032)2683556'>(032)2683556</a>
                </p>
              </Col>
            </Row>
            </a>
            <a className='linkName' target="_blank" href='tel: +63 32 410 7727'>
            <Row className='contactSecondRow'>
              <Col className='col-2'>
                <FontAwesomeIcon className='iconMobile' icon={faMobileRetro}/>
              </Col>
              <Col className='col-6'>
                <p className='contactTitle'><b>Mobile</b><br></br>0932 3434 348</p>
              </Col>
            </Row>
            </a>
          </Col>
          <Col className='contactSecondCol'>
            <div>
                <iframe 
                        width="400" 
                        height="370" 
                        frameborder="0" 
                        scrolling="no" 
                        marginheight="0" 
                        marginwidth="0" 
                        className='gmap' 
                        src="https://maps.google.com/maps?width=520&amp;height=529&amp;hl=en&amp;q=ipars%20cebu%20Cebu%20City+()&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                ></iframe>

            </div>
          </Col>    
      </Row>

      </div>     
    </div>
    )
  }
}

export default Contact