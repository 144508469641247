import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import grab from '../../Assets/grab.png'

import './Footer.css'

function Footer(){
    return(<>
    <div className='footer'>
        <div>
            <Row className='iconRow'>
                <Col className='col-1'>
                    <a target="_blank" href='https://www.facebook.com/IparsCebu/'>
                    <FontAwesomeIcon className='footerIcon' icon={faFacebook}/>
                    </a>
                </Col>
                <Col className='col-1'>
                    <a target="_blank" href='https://www.instagram.com/iparsrestaurantecebu/'>
                    <FontAwesomeIcon className='footerIcon' icon={faInstagram}/>
                    </a>
                </Col>
                <Col className='col-1'>
                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                        <img 
                            className="footerIconGrab"
                            src={grab}
                            alt="grab"/>
                    </a>
                </Col>
            </Row>
        </div>
        <div>
            <hr
                className='line' style={{color: '#FFA500', opacity: '100'}}
        />
        </div>
        <div className='footerText'>
            <p>
                Ipar's Restaurante y Bar de Tapas ⓒ 2022
            </p>
        </div>
    </div>
    
    
    </>)
}
export default Footer