import React, {useState} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Footer from '../../components/Footer/Footer'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';
import { Card, Modal} from 'react-bootstrap';
import { CircleFlag } from 'react-circle-flags'
import grab from '../../Assets/Modal/GrabFood.png'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import "./Ensaladas.css";

import Menu from '../../Pages/Menu/Menu';
import Sopas from '../Sopas/Sopas';
import Paellas from '../Paellas/Paellas';
import Pescados from '../Pescados/Pescados';
import Carnes from '../Carnes/Carnes';
import Aves from '../Aves/Aves';
import Postres from '../Postres/Postres';
import SideOrders from '../SideOrders/SideOrders';

import empty from '../../Assets/Tapas/Specialty1.jpg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'




function Ensaladas() {
const { t } = useTranslation();

function handleClick(lang) {
  i18next.changeLanguage(lang)
}

const [mixta, setMixta] = useState(false);
const [ensaladaDePollo, setEnsaladaDePollo] = useState(false);
const [ensaladaDeAtun, setEnsaladaDeAtun] = useState(false);
  return (
    <div className='ensaladasContainer'>
      <div className='menuHeader'>
          <p>Our Menu</p>
      </div>
      <div className='options-container'>
        <div className='options-wrapper'>

          <div>
            <nav className='dropdownNav'>
              {[DropdownButton].map((DropdownType, idx) => (
                <DropdownType
                  as={ButtonGroup}
                  key={idx}
                  id={`dropdown-button-drop`}
                  size="sm"
                  variant="dark"
                  menuVariant="transparent"
                  title="Ensaladas"
                >
                  <Dropdown.Item eventKey="1"><Link to="/menu" className='menu-option'>Tapas</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="2"><Link className='menuPicked'>Ensaladas</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="3"><Link to="/sopas" className='menu-option' name="sopas">Sopas</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="4"><Link to="/paellas" className='menu-option' name="paellas">Paellas</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="5"><Link to="/pescados" className='menu-option' name="pescados">Pescados y Mariscos</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="6"><Link to="/carnes" className='menu-option' name="carnes">Carnes</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="7"><Link to="/aves" className='menu-option' name="aves">Aves</Link></Dropdown.Item>
                  <Dropdown.Item eventKey="8"><Link to="/postres" className='menu-option' name="postres">Postres</Link></Dropdown.Item>
                  <Dropdown.Item eventKey=""><Link to="/sideOrders" className='menu-option' name="side">Side Orders</Link></Dropdown.Item>
                </DropdownType>
              ))}
            </nav>
            <nav className="navbar">
              <Link to="/menu" className='menu-option'>Tapas</Link>
              <Link className='menuPicked'>Ensaladas</Link>
              <Link to="/sopas" className='menu-option'>Sopas</Link>
              <Link to="/paellas" className='menu-option'>Paellas</Link>
              <Link to="/pescados" className='menu-option'>Pescados y Mariscos</Link>
              <Link to="/carnes" className='menu-option'>Carnes</Link>
              <Link to="/aves" className='menu-option'>Aves</Link>
              <Link to="/postres" className='menu-option'>Postres</Link>
              <Link to="/sideOrders" className='menu-option'>Side Orders</Link>

            </nav>
              <Routes>
                <Route path="/menu" element={<Menu/>} />
                <Route path="/sopas" element={<Sopas/>} />
                <Route path="/paellas" element={<Paellas/>} />
                <Route path="/pescados" element={<Pescados/>} />
                <Route path="/carnes" element={<Carnes/>} />
                <Route path="/aves" element={<Aves/>} />
                <Route path="/postres" element={<Postres/>} />
                <Route path="/sideOrders" element={<SideOrders/>} />

              </Routes>
          </div>


        </div>
      </div>
      <Row className='ensaladasRow'>
            <Col sm={'auto'} style={{ width: "18vw" }}>
                <Card onClick={() => setMixta(true)} className='card'>
                    <Card.Img className='cardImage'variant="top" src={empty} />
                    <Card.Body>
                    <Card.Title className='cardTitle'>ENSALADA MIXTA</Card.Title>
                    </Card.Body>
                </Card>
                <Modal className='modalForm' backdrop="static" show={mixta} onHide={() => setMixta(false)} closeButton>
                <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setMixta(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('ensaladasTitle.1')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                   
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('ensaladasDescription.1')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱300.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$5.09</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€5.21</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
            </Col>
            <Col sm={'auto'} style={{ width: "18vw" }}>
                <Card onClick={() => setEnsaladaDePollo(true)}className='card'>
                    <Card.Img className='cardImage'variant="top" src={empty} />
                    <Card.Body>
                    <Card.Title className='cardTitle'>ENSALADA DE POLLO<br></br>Y PATATA</Card.Title>
                    </Card.Body>
                </Card>
                <Modal className='modalForm' backdrop="static" show={ensaladaDePollo} onHide={() => setEnsaladaDePollo(false)} closeButton>
                <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setEnsaladaDePollo(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('ensaladasTitle.2')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('ensaladasDescription.2')}</p>
                                    </Row>
                                    <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱280.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$4.76</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€4.86</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
            </Col>
            <Col className='ensaladaCol3' sm={'auto'} style={{ width: "18vw" }}>
                <Card onClick={() => setEnsaladaDeAtun(true)}className='card'>
                    <Card.Img className='cardImage'variant="top" src={empty} />
                    <Card.Body>
                    <Card.Title className='cardTitle'>ENSALADA DE ATUN<br></br>Y PATATA</Card.Title>
                    </Card.Body>
                </Card>
                <Modal className='modalForm' backdrop="static" show={ensaladaDeAtun} onHide={() => setEnsaladaDeAtun(false)} closeButton>
                <FontAwesomeIcon className='menuCloseIcon' icon={faCircleXmark} onClick={() => setEnsaladaDeAtun(false)}/>
                        <img className='modalImage' src={empty}/>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Row md={4}>
                                        <Col md={'auto'}>
                                            <p className='paragraph'><b>{t('ensaladasTitle.3')}</b></p>
                                        </Col>
                                    </Row>
                                    <Row md={4}>
                                        <Col md={5}>
                                            <p className='translate'>Translate:</p>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('en')} className='flag' countryCode="um"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('jp')} className='flag' countryCode="jp"/>
                                        </Col>
                                        <Col md={2}>
                                            <CircleFlag onClick={()=>handleClick('kr')} className='flag' countryCode="kr"/>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                                <Col className='col-5 me-2'>
                                    <a target="_blank" href='https://grab.onelink.me/2695613898?pid=inappsharing&c=PHGFSTI0000034q&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3DPHGFSTI0000034q&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com%2Fdownload'>
                                        <img className='modalPhoto' src={grab}/>
                                    </a>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className='menuDescription'>{t('ensaladasDescription.3')}</p>
                                    </Row>
                                  <Row>
                                        <Col className='col-3 menuThree'>
                                            <p className='priceName'><b>₱280.00</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>$4.76</b></p>
                                        </Col>
                                        <Col className='col-2 menuTwo'>
                                            <p className='priceName'><b>€4.86</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className='modalFooter'><i>Prices are all VAT inclusive and may also vary or be subject to change</i></p>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
            </Col>
        </Row>

        <Footer/>

      </div>
  )
}

export default Ensaladas